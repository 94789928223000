@font-face {
  font-family: "Code Bold";
  src: url("Code-Bold.woff2") format("woff2"),
    url("Code-Bold.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura Light";
  src: url("Futura-Light.woff2") format("woff2"),
    url("Futura-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Futura Regular";
  src: url("Futura-Regular.woff2") format("woff2"),
    url("Futura-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
